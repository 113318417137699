import { PricingRule } from "@/interfaces/product";

export interface PriceGroupInterface {
  id: string | null;
  is_active: boolean;
  name: string;
  rule_set_id: number;
  pricing_style: string;
  pricing_group_id: number | null;
  rule_type: string;
  rules: PricingRule[];
  uid: string;
}
export interface PriceGroups {
  id?: number | null;
  is_active: boolean;
  name: string | null;
  rule_type: string;
  rules: PricingRule[] | [];
  group_type: "PRICING_GROUP" | "BATCH_LEVEL";
  pricing_style?: string;
  pricing_group_id?: string | number | null;
}

export const defaultPriceGroup: PriceGroups = {
  is_active: true,
  name: null,
  rule_type: "PRICE_BREAK_RULE",
  rules: [],
  group_type: "PRICING_GROUP",
  pricing_group_id: 0
};

export interface PriceGroupPayload {
  source: string;
  price_points: PricePoints[];
  price_group: PriceGroups;
}

export interface PricePoints {
  location_id: number;
  code: string;
  is_active: boolean;
  rules: PricingRule[] | [];
}
