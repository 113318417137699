import { Product } from "@/interfaces/product";
import { Callback } from "@/types/types";
import debounce from "lodash/debounce";
import { Validator } from "vee-validate";
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./PricePointCopy.template.vue";

/**
 * namespace to dispatch vuex actions and getters
 * @const string namespace
 */
const namespace: string = "PricePointModule";

/**
 * product price point component
 */
@Component({
  mixins: [Template]
})
export default class PricePointCopyComponent extends Vue {
  /**
   * validator service
   * @var Validator $validator
   */
  @Inject("$validator") public $validator!: Validator;

  @Action("findProducts", { namespace })
  public findProductsAction!: Callback;

  protected productToCopy: Product | null = null;

  @Getter("products", { namespace })
  protected products!: Product[];

  protected searchProduct: string = "";

  protected isLoading: boolean = false;

  private debounceSearch = debounce(async (name: string) => {
    this.isLoading = true;
    await this.findProductsAction(name);
    this.isLoading = false;
  }, 500);

  /**
   * watcher to dispatch the findVendorsAction action, when the autocomplete field is change
   * @param vendorName
   */
  @Watch("searchProduct")
  public search(searchProduct: string) {
    this.debounceSearch(searchProduct);
  }

  /**
   * method to validate and call the store save action
   */
  public async save() {
    const validator = await this.$validator.validateAll();
    if (validator && this.productToCopy) {
      this.$emit("resolve", this.productToCopy);
    }
  }

  public cancel() {
    this.$emit("reject");
  }
}
