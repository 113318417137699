import { PriceModel } from "@/interfaces/product";
import { RetailSettings } from "@/interfaces/retailSettings";
import { TaxCategory } from "@/interfaces/taxCategory";
import { pricePointService } from "@/services/pricePoint.service";
import { Callback } from "@/types/types";
import debounce from "lodash/debounce";
import find from "lodash/find";
import { Validator } from "vee-validate";
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./PricePointForm.template.vue";

const namespace: string = "PricePointModule";

@Component({
  mixins: [Template]
})
export default class PricePointFormComponent extends Vue {
  public postTaxPricing = false;

  @Inject("$validator") public $validator!: Validator;
  @Action("save", { namespace })
  public saveAction!: Callback;
  public test = 1;
  public errorMessage = {
    amount_units: false,
    amount_weight: false,
    price: false,
    post_tax: false
  };
  @Prop({ default: null })
  protected productUnit!: string;
  @Prop({ required: true })
  protected pricePoint!: PriceModel;
  @Prop({ required: true })
  protected tax!: TaxCategory;
  @Prop({ required: true })
  protected memberLevelCode!: string;
  @Prop({ default: false })
  protected modifyBatchLevelPricingPermission!: boolean;
  @Prop({ required: true })
  protected prices!: PriceModel[];
  @Prop()
  protected priceByUsable!: boolean;
  @Prop()
  protected requiresWeighing!: boolean;
  @Prop()
  protected wholesale!: boolean;
  @Prop({ default: "" })
  protected pricingStyleSelected!: string;
  @Prop({ default: "" })
  protected pricingTypeSelected!: string;

  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  protected retailSettings!: RetailSettings;

  protected forwardDebounce = debounce(async (scope: any) => {
    if (scope.calculateForward) {
      scope.pricePoint = pricePointService.calculateForward(
        scope.pricePoint,
        scope.groupedTaxes
      );
    }
  }, 250);
  protected backwardDebounce = debounce(async (scope: any) => {
    if (!scope.calculateForward) {
      scope.pricePoint = pricePointService.calculateBackwards(
        scope.pricePoint,
        scope.groupedTaxes
      );
    }
  }, 250);

  public get groupedTaxes() {
    const taxes = this.tax.taxes || [];
    return pricePointService.getGroupedTaxes(taxes);
  }

  protected calculateForward = true;

  public created() {
    this.pricePoint.tax_category_id = this.tax.id;
    this.pricePoint.tax_category = this.tax;
    this.pricePoint.member_level_code = this.memberLevelCode;
    this.postTaxPricing = !!(this.wholesale
      ? !this.retailSettings!.wholesale_pre_tax_pricing
      : !this.retailSettings!.retail_pre_tax_pricing);
  }

  public get amountSuffix() {
    let suffix;
    if (this.pricingStyleSelected) {
      return this.pricingStyleSelected === "units" ? "u" : "g";
    }
    if (this.priceByUsable || this.requiresWeighing !== undefined) {
      suffix =
        this.priceByUsable || this.requiresWeighing ? this.productUnit : "u";
    }
    return suffix || "";
  }

  @Watch("pricePoint.price")
  public calculatePostTax() {
    this.forwardDebounce(this);
  }

  @Watch("pricePoint.post_tax")
  public calculatePrice() {
    this.backwardDebounce(this);
  }

  public getRules(type?: string) {
    if (this.errorMessage.amount_units && type === "amount") {
      return this.$t("price_points_amount_weight_error");
    }
    if (this.errorMessage.amount_weight && type === "amount") {
      return this.$t("price_points_amount_units_error");
    }
    if (this.errorMessage.price && type === "price") {
      return this.$t("price_points_price_error");
    }
    if (this.errorMessage.post_tax && type === "post_tax") {
      return this.$t("price_points_post_tax_error");
    }
  }
  public validateNumber(sense: string, value?: string, type?: string) {
    this.calculateForward = sense === "forwards";
    if (this.pricingStyleSelected || this.pricingTypeSelected) {
      if (type === "amount" && this.pricingStyleSelected === "units") {
        if (value && !/^[0-9]{1,7}?$/.test(value)) {
          this.test += 1;
          this.$nextTick(() => {
            (this.$refs.quantity_input_ as HTMLElement).focus();
          });
          setTimeout(() => {
            this.errorMessage.amount_units = false;
          }, 2500);
          this.errorMessage.amount_units = true;
        } else {
          this.errorMessage.amount_units = false;
          this.pricePoint.quantity = Number(value);
        }
      }
      if (
        type === "amount" &&
        (this.pricingStyleSelected === "weight" || this.pricingTypeSelected)
      ) {
        if (value && !/^[0-9]{0,7}(?:\.[0-9]{1,3})?$/.test(value)) {
          this.test += 1;
          this.$nextTick(() => {
            (this.$refs.quantity_input_ as HTMLElement).focus();
          });
          setTimeout(() => {
            this.errorMessage.amount_weight = false;
          }, 2500);
          this.errorMessage.amount_weight = true;
        } else {
          this.errorMessage.amount_weight = false;
          this.pricePoint.quantity = Number(value);
        }
      }
      if (type === "post_tax") {
        if (value && !/^[0-9]{0,7}(?:\.[0-9]{1,2})?$/.test(value)) {
          this.test += 1;
          this.$nextTick(() => {
            (this.$refs.quantity_input_post_tax as HTMLElement).focus();
          });
          setTimeout(() => {
            this.errorMessage.post_tax = false;
          }, 2500);
          this.errorMessage.post_tax = true;
        } else {
          this.errorMessage.post_tax = false;
          this.pricePoint.post_tax = Number(value);
        }
      }
      if (type === "price") {
        if (value && !/^[0-9]{0,7}(?:\.[0-9]{1,2})?$/.test(value)) {
          this.test += 1;
          this.$nextTick(() => {
            (this.$refs.quantity_input_price as HTMLElement).focus();
          });
          setTimeout(() => {
            this.errorMessage.price = false;
          }, 2500);
          this.errorMessage.price = true;
        } else {
          this.errorMessage.price = false;
          this.pricePoint.price = Number(value);
        }
      }
    } else {
      if (type === "amount") {
        this.pricePoint.quantity = Number(value);
      } else if (type === "price") {
        this.pricePoint.price = Number(value);
      } else {
        this.pricePoint.post_tax = Number(value);
      }
    }
  }

  public validateDecimal(value: KeyboardEvent, type?: string) {
    if (this.pricingStyleSelected || this.pricingTypeSelected) {
      if (
        type === "post_tax" &&
        Number(value.key) === 0 &&
        Number(this.pricePoint.post_tax) === 0
      ) {
        setTimeout(() => {
          this.errorMessage.post_tax = true;
        }, 250);
      }
      if (
        type === "price" &&
        Number(value.key) === 0 &&
        Number(this.pricePoint.price) === 0
      ) {
        setTimeout(() => {
          this.errorMessage.price = true;
        }, 250);
      }
      if (
        type === "amount" &&
        (this.pricingStyleSelected === "weight" || this.pricingTypeSelected) &&
        Number(value.key) === 0 &&
        Number(this.pricePoint.quantity) === 0
      ) {
        setTimeout(() => {
          this.errorMessage.amount_weight = true;
        }, 250);
      }
      if (
        type === "amount" &&
        this.pricingStyleSelected === "units" &&
        Number(value.key) === 0 &&
        Number(this.pricePoint.quantity) === 0
      ) {
        setTimeout(() => {
          this.errorMessage.amount_units = true;
        }, 250);
      }
      if (
        value.key === "." &&
        this.pricingStyleSelected === "units" &&
        type === "amount"
      ) {
        this.errorMessage.amount_units = true;
        setTimeout(() => {
          this.errorMessage.price = false;
        }, 2500);
        value.preventDefault();
      }
    }
  }
  public validateExist() {
    const exist = find(this.prices, (pricePoint: PriceModel, index: number) => {
      return (
        ((this.pricePoint.isEditing &&
          this.pricePoint.editingIndex !== index) ||
          !this.pricePoint.isEditing) &&
        +this.pricePoint.quantity! === +pricePoint.quantity!
      );
    });
    if (exist) {
      this.$validator.errors.add({
        field: "amount",
        msg: this.$t("amount_exist_error").toString()
      });
      return false;
    }
    return true;
  }
  public get pricePointPrice() {
    return this.postTaxPricing
      ? this.pricePoint.price.toString()
      : parseFloat(this.pricePoint.price.toString());
  }
  public get pricePointPostTax() {
    return this.postTaxPricing
      ? parseFloat(this.pricePoint.post_tax!.toString())
      : this.pricePoint.post_tax!.toString();
  }
  public async addNewPricePoint() {
    const validator = await this.$validator.validateAll();
    if (validator) {
      if (!this.validateExist()) {
        return;
      }
      if (this.calculateForward) {
        this.forwardDebounce.flush();
      } else {
        this.backwardDebounce.flush();
      }
      this.$emit("resolve", {
        edit: this.pricePoint.isEditing,
        data: this.pricePoint
      });
    }
  }
  public cancel() {
    this.$emit("reject");
  }
}
