import PricePointMainComponent from "@/components/inventory/pricePoint/pricePointMain/PricePointMain.component";
import { Location } from "@/interfaces/location";
import { MemberLevel, MemberLevelType } from "@/interfaces/memberLevel";
import { PriceGroupInterface } from "@/interfaces/priceGroups";
import { PriceTab, PricingRule } from "@/interfaces/product";
import { TaxCategory } from "@/interfaces/taxCategory";
import { EventBus } from "@/internal";
import { memberLevelService } from "@/services/memberLevel.service";
import { messagesService } from "@/services/messages.service";
import { taxCategoryService } from "@/services/taxCategory.service";
import cloneDeep from "lodash/cloneDeep";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./PricePointStructure.template.vue";

/**
 * product price point component
 */
@Component({
  mixins: [Template],
  components: {
    PricePointMainComponent
  }
})
export default class PricePointStructureComponent extends Vue {
  public get prices() {
    const priceType = this.tabs[this.activeTab].type;
    return this.value.filter(price => price.pricing_type === priceType);
  }
  public tabs: PriceTab[] = [
    {
      type: "WHOLESALE_INBOUND",
      title: "product_details.wholesale_inbound",
      isWholesale: true
    },
    {
      type: "WHOLESALE_OUTBOUND",
      title: "product_details.wholesale_outbound",
      isWholesale: true
    }
  ];
  public loading = true;
  public activeTab = 0;
  public locationIsRecreational = false;
  public locationIsMedical = false;
  @Prop({ default: true }) public isCustomOption!: boolean;
  @Prop({ default: false }) public batchSelected!: boolean;
  @Prop({ default: false }) public productSelected!: boolean;
  @Prop({ default: false })
  public isDisable!: boolean;
  @Prop() public priceGroupSelectedId!: number | null;
  @Prop() public priceGroupAssigned!: PriceGroupInterface;
  @Prop({ default: false })
  protected modifyBatchLevelPricingPermission!: boolean;
  @Prop({ required: true })
  protected currentLocation!: Location;
  @Prop({ default: null })
  protected unitUsableWeight!: string;
  @Prop({ default: () => [] })
  protected value!: PricingRule[];
  @Prop({ default: false })
  protected priceByUsable!: boolean;
  @Prop({ default: false })
  protected requiresWeighing!: boolean;
  @Prop({ default: true })
  protected isModifyAblePriceGroup!: boolean;
  @Prop({ default: true })
  protected isModifiableProducts!: boolean;
  @Prop({ default: "" })
  protected pricingStyleSelected!: string;
  @Prop({ default: "" })
  protected pricingTypeSelected!: string;
  @Prop({ default: false })
  protected isOnlyPriceGroup!: boolean;
  /**
   * taxCategories
   * @var TaxCategory[] taxCategories
   */
  protected taxCategories: TaxCategory[] = [];

  /**
   * memberLevels
   * @var MemberLevel[] memberLevels
   */
  protected memberLevels: MemberLevel[] = [];

  public copyPrices(copiedPrices: PricingRule[]) {
    const oldRules = cloneDeep(this.value);
    const newPrices = copiedPrices.reduce((acc: PricingRule[], rule) => {
      const indexOldRule = oldRules.findIndex(
        r =>
          r.member_level_code === rule.member_level_code &&
          r.pricing_type === rule.pricing_type
      );
      const oldRule = indexOldRule > -1 ? oldRules.splice(indexOldRule, 1) : [];
      const newRule = oldRule.length ? { ...rule, id: oldRule[0].id } : rule;
      acc.push(newRule);
      return acc;
    }, []);
    messagesService.renderSuccessMessage("price_point_copy_success");
    this.$emit("input", [
      ...newPrices,
      ...oldRules.map(d => ({ ...d, price_breaks: [] })) // Se coloca price_breaks vacio para desactivar la regla
    ]);
  }

  public setPrices(event: { rules: PricingRule[]; priceType: string }) {
    const otherRules: PricingRule[] = this.value!.filter(
      price => price.pricing_type !== event.priceType
    );
    this.$emit("input", [...otherRules, ...event.rules]);
  }
  public setPricingStyle(event: string) {
    this.prices[0].pricing_style = event;
  }
  public buildTabs() {
    this.tabs = [
      {
        type: "WHOLESALE_INBOUND",
        title: "product_details.wholesale_inbound",
        isWholesale: true
      },
      {
        type: "WHOLESALE_OUTBOUND",
        title: "product_details.wholesale_outbound",
        isWholesale: true
      }
    ];
    if (this.locationIsRecreational) {
      this.tabs.unshift({
        type: "RETAIL_RECREATIONAL",
        title: "product_details.recreational_price_point",
        isWholesale: false
      });
    } else if (!this.locationIsRecreational) {
      const tabIndex = this.tabs.findIndex(
        tab => tab.type === "RETAIL_RECREATIONAL"
      );
      if (tabIndex !== -1) {
        this.tabs.splice(tabIndex, 1);
      }
    }

    if (this.locationIsMedical) {
      this.tabs.unshift({
        type: "RETAIL_MEDICAL",
        title: "product_details.medical_price_point",
        isWholesale: false
      });
    } else if (!this.locationIsMedical) {
      const tabIndex = this.tabs.findIndex(
        tab => tab.type === "RETAIL_MEDICAL"
      );
      if (tabIndex !== -1) {
        this.tabs.splice(tabIndex, 1);
      }
    }
  }

  public deleteRules() {
    this.value.forEach(item => {
      if (this.activeTab === 0 && item.pricing_type === "RETAIL_MEDICAL") {
        item.price_breaks = [];
      } else if (
        this.activeTab === 0 &&
        item.pricing_type === "RETAIL_RECREATIONAL"
      ) {
        item.price_breaks = [];
      } else if (
        this.activeTab === 1 &&
        item.pricing_type === "WHOLESALE_INBOUND"
      ) {
        item.price_breaks = [];
      } else if (
        this.activeTab === 2 &&
        item.pricing_type === "WHOLESALE_OUTBOUND"
      ) {
        item.price_breaks = [];
      }
    });
  }

  protected setLocationTypes() {
    this.locationIsRecreational =
      this.currentLocation.location_type === "RETAIL_RECREATIONAL" ||
      this.currentLocation.location_type === "RETAIL_MEDICAL_RECREATIONAL";

    this.locationIsMedical =
      this.currentLocation.location_type === "RETAIL_MEDICAL" ||
      this.currentLocation.location_type === "RETAIL_MEDICAL_RECREATIONAL";
  }
  protected async mounted() {
    const noneMemberLevel: MemberLevel = {
      id: null,
      code: null,
      name: this.$t("inventory.none").toString()
    };
    this.setLocationTypes();
    this.buildTabs();
    [this.taxCategories, this.memberLevels] = await Promise.all([
      taxCategoryService.getAll(),
      memberLevelService.get({ "q[type_eq]": MemberLevelType.PRICE_POINT })
    ]);

    this.memberLevels = this.memberLevels.filter((memberLevel: MemberLevel) => {
      return memberLevel.is_active;
    });
    this.memberLevels = [...this.memberLevels, noneMemberLevel];
    this.loading = false;

    EventBus.$on(
      "onChangePriceGroupLocation",
      async (locationId: string, locationSelected: Location) => {
        this.locationIsRecreational =
          locationSelected.location_type === "RETAIL_RECREATIONAL" ||
          locationSelected.location_type === "RETAIL_MEDICAL_RECREATIONAL";

        this.locationIsMedical =
          locationSelected.location_type === "RETAIL_MEDICAL" ||
          locationSelected.location_type === "RETAIL_MEDICAL_RECREATIONAL";
        this.taxCategories = await taxCategoryService.get({
          location_id: locationId
        });
        this.buildTabs();
      }
    );
  }
}
